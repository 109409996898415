import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"
import ContactUsForm from "../components/contact-us-form"
import Customers from "../components/customers"

const options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const type = node?.data?.target?.__typename || null

      if (type !== "ContentfulTextGradient") {
        return children
      }

      return <h1 className="text-white max-w-2xl mx-auto big-h1">Plans and pricing</h1>
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return <h1 className="text-white">{children}</h1>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p className="text-white mx-auto text-lg" style={{ maxWidth: '55%' }}>Submit your pricing request today! Our team will create a customized quote tailored to your company's requirements.</p>
    },
  },
}

const iconOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p 
      className='leading-tight relative z-10 justify-center font-medium text-white text-center sm:text-base md:text-xs lg:text-sm my-lg xl:text-base 2xl:text-xl sm:leading-tight md:leading-tight lg:leading-tight xl:leading-tight 2xl:leading-tight demo-icon-text'
    > {children} </p>  
    },
  },
}

const PricingRequest = ({ location }) => {
  const { data } = useStaticQuery(
    graphql`
    query {
      data: contentfulPageDemo {
        title
        description
        heroContent {
          raw
          references {
            ... on ContentfulTextGradient {
              contentful_id
              __typename
              content
            }
          }
        }
        background {
          gatsbyImageData(
            placeholder: NONE
          )
        }
        heroImageBackground {
          gatsbyImageData(
            quality: 70
            placeholder: NONE
          )
        }
        heroIcons {
          integer
          measurementUnit
          description {
            raw
          }
          icon {
            gatsbyImageData(
              placeholder: BLURRED
            )
            title
          }
        }
        featuredImage {
          gatsbyImageData(
            quality: 100
            placeholder: NONE
          )
        }
        formTitle
      }
    }
    `
  )

  const content = data?.heroContent || "";
  const formTitle = "Get Personalized pricing";
  const heroImageBackground = data?.heroImageBackground;
  const featuredImage = data?.featuredImage;
  const heroIcons = data?.heroIcons || [];

  return (
    <Layout location={location}>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,500;1,100&family=Roboto:wght@300;400;700&display=swap" rel="stylesheet"></link>
      <div className="bg-white">
        <div className="w-full flex flex-wrap">
        <div className="w-full md:w-1/2 relative shadow-2xl">
          <div className="flex flex-col sticky top-0 w-full">
            <div className="grid" style={{ padding: 0 }}>
              <GatsbyImage
                style={{ gridRow: 1, gridColumn: 1, maxWidth: '100%'  }}
                image={data.background.gatsbyImageData}
                alt={''}
              />                
              <div className='w-full max-w-6xl m-auto 3xl:mt-32' style={{ gridRow: 1, gridColumn: 1, zIndex: 1 }} >
                <div className='justify-center mx-auto mt-4 text-center'>
                  {content && renderRichText(content, options)}
                </div> 
                <div className='my-2 lg:my-3' style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gridTemplateRows: 'auto' }}>
                  <GatsbyImage
                    className='z-10'
                    style={{ gridArea: '1 / 4 / 2 / 5', overflow: 'visible' }}
                    imgStyle={{ maxWidth: '70%', maxHeight: '65%', margin: '8px auto', transform: 'translateY(-40%)' }}
                    image={featuredImage.gatsbyImageData}
                    alt={''}
                  />  
                  <div style={{ gridArea: '1 / 1 / 1 / 5' }}>
                    <div className='grid' style={{ gridTemplateColumns: '0.65fr 1fr 1fr 1fr 0.65fr', gridTemplateRows: '1fr 1fr 1fr', maxWidth: '90%', maxHeight: '90%', margin: '8px auto' }}>
                    <GatsbyImage
                      style={{ gridArea: '1 / 1 / 4 / 6' }}
                      image={heroImageBackground.gatsbyImageData}
                      imgStyle={{ maxWidth: '100%', maxHeight: '100%'}}
                      alt={''}
                    />
                    {heroIcons.map((icon, index) => (
                      <>
                        <GatsbyImage
                          image={icon.icon.gatsbyImageData}
                          alt={icon.icon.title}
                          style={{ gridArea: `1 / ${index + 2} / 1 / ${index + 3}`, display: 'flex', justifyContent: 'center', maxWidth: '40%', maxHeight: '60%', margin: 'auto auto 0 auto' }}
                        />
                        <span 
                          style={{ gridArea: `2 / ${index + 2} / 2 / ${index + 3}`, fontFamily: 'Poppins' }} 
                          className='flex justify-center font-semibold text-white text-center'>
                            <span 
                              style={{ fontFamily: 'Poppins' }} 
                              className='my-auto ml-auto mr-0 relative z-10 sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl demo-icon-percent'
                            >
                              {icon.integer}
                            </span>
                            <span className='relative z-10' style={{ margin: '10% auto auto 0'}}>{icon.measurementUnit}</span>
                          </span>
                          <div style={{gridArea: `3 / ${index + 2} / 3 / ${index + 3}`, margin: '0 auto auto auto' }} >
                            {renderRichText(icon.description, iconOptions)}
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <p className="text-lg text-center text-gray-light font-normal opacity-75">
                  LEADING PLAYERS
                </p>
                <h3 className='mb-6 mx-2 text-white text-center'>Validating Every Code Change At</h3>
                <Customers all/>
              </div>
            </div>
          </div>
        </div>

          <div className="w-full md:w-1/2 flex flex-col">
            <div className="flex flex-col justify-center max-w-xl w-full my-auto mx-auto px-4 md:justify-start md:px-12">
              <h2 className='mx-auto mt-16 mb-8 text-indigo-900'>{formTitle}</h2>
              <ContactUsForm
                formName="pricing-request"
                buttonText="REQUEST YOUR QUOTE"
                buttonClassName="submit-demo-button"
                wrapperButtonClassName="flex justify-center"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PricingRequest
